export function handleFormLabels() {
  const forms = [...document.querySelectorAll("div.gform_wrapper")];
  if (forms) {
    forms.forEach(form => {
      const formRows = [...form.querySelectorAll("li")];
      // Perform initial check to correctly position labels after a failed ajax request
      formRows.forEach(row => {
        const label = row.querySelector("label");
        const input =
          row.querySelector("input") || row.querySelector("textarea");
        if (input && input.type !== "checkbox") {
          if (input.value !== "") {
            label.classList.add("js-form-label");
          }
        }
      });
      // Move the password around

      const loginLinks = form.querySelector(".gf_login_links");
      if (loginLinks) {
        const footer = form.querySelector(".gform_footer");
        if (footer) {
          footer.appendChild(loginLinks);
          loginLinks.setAttribute("style", "position: absolute; right: 0;");
        }
      }

      // Add event listeners
      // Special case for complex container

      formRows.forEach(row => {
        const specialContainer = row.querySelector(
          ".gfield_label_before_complex"
        );
        if (specialContainer) {
          // Special case for complex container
          const specialInputs = [...row.querySelectorAll("input")];
          if (specialInputs) {
            specialInputs.forEach(specialInput => {
              const specialLabel = row.querySelector(
                `label[for='${specialInput.getAttribute("id")}']`
              );
              specialInput.addEventListener("focus", e => {
                specialLabel.classList.add("js-form-label");
              });
              specialInput.addEventListener("blur", e => {
                if (specialInput.value == "") {
                  specialLabel.classList.remove("js-form-label");
                }
              });
              if (specialInput.value !== "") {
                specialLabel.classList.add("js-form-label");
              }
            });
          }
        } else {
          const label = row.querySelector("label");
          const input =
            row.querySelector("input") || row.querySelector("textarea");
          //   console.log(input)
          if (input && input.type !== "checkbox") {
            input.addEventListener("input", function(e) {
              if (e.srcElement.value == "") {
                label.classList.remove("js-form-label");
              } else {
                label.classList.add("js-form-label");
              }
            });
            // input.addEventListener('focus', (e) => {
            //   label.classList.add('js-form-label')
            //   input
            // })
            // input.addEventListener('blur', (e) => {
            //   if (input.value == '') {
            //     label.classList.remove('js-form-label')
            //   }
            // })
          }
        }
      }); // End of formRows.forEach
    });
  }
}

export function openLoginModalAfterFailedSubmission() {}
