const throttle = require('lodash.throttle')

function onFilterClick(filter) {
  // The function that runs when a news filter item is clicked
  const articles = [...document.querySelectorAll('.js-news-article')]
  const filters = [...document.querySelectorAll('.js-news-filter')]
  if (articles) {
    const shownArticles = []
    articles.forEach((article) => {
      // if articles published date matches the filters, show it
      if (article.dataset.published === filter.dataset.published) {
        shownArticles.push(article)
      } else if (filter.dataset.published === 'all') {
        // if its the all button, we add it all the same
        shownArticles.push(article)
      } else {
        // otherwise hide it
        article.classList.add('animate-out')
        article.classList.remove('animate-in')
      }
    })
    // Have articles fade in
    shownArticles.forEach((_article) => {
      _article.classList.add('animate-in')
      _article.classList.remove('animate-out')
    })

    // go through each filter and show the one that was clicked
    filters.forEach((_filter) => {
      if (_filter === filter) {
        _filter.classList.add('active')
      } else {
        // hide the rest
        _filter.classList.remove('active')
      }
    })
    // Set window scroll to top
    window.scrollTo(0, 0)
  }
}

export function filterNews() {
  // Get all filters and articles
  const filters = [...document.querySelectorAll('.js-news-filter')]
  const articles = [...document.querySelectorAll('.js-news-article')]

  if (filters && articles) {
    filters.forEach((filter) => {
      filter.addEventListener('click', (e) => {
        e.preventDefault()
        onFilterClick(filter)
      })
    })
  }
  // Init the all filter
  if (filters) {
    filters.forEach((filter) => {
      if (filter.dataset.published == 'all') {
        filter.click()
      }
    })
  }
}

function moveNewsFilter() {
  const target = document.querySelector('.js-sticky-news')
  if (target) {
    const targetPosition = target.getBoundingClientRect()
    const targetOffsetTop = targetPosition.top
    const currentScroll = window.scrollY
    if (currentScroll > targetOffsetTop) {
      target.setAttribute(
        'style',
        `transform: translate3d(0, ${currentScroll - targetOffsetTop}px, 0)`,
      )
    }
  }
}

export function stickyNewsFilter() {
  const target = document.querySelector('.js-sticky-news')
  if (target) {
    window.addEventListener('scroll', throttle(moveNewsFilter), 100)
  }
}

function closeDropdown(heightWhenClosed, trigger) {
  const dropdown = document.querySelector('.js-dropdown')
  if (dropdown && trigger) {
    trigger.classList.remove('js-dropdown-is-open')
    dropdown.dataset.open = 'false'
    dropdown.setAttribute('style', `height: ${heightWhenClosed}px;`)
    setTimeout(() => {
      dropdown.setAttribute('style', '')
    }, 1)
  }
}

export function handleNewsDropdown() {
  if (window.innerWidth < 800) {
    const dropdown = document.querySelector('.js-dropdown')
    if (dropdown) {
      const ddTrigger = dropdown.querySelector('.js-dropdown-trigger')
      const dropdownHeightWhenClosed = dropdown.getBoundingClientRect().height
      ddTrigger.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (dropdown.dataset.open === 'false') {
          dropdown.dataset.open = 'true'
          dropdown.classList.add('js-full-height')
          ddTrigger.classList.add('js-dropdown-is-open')
          const dropdownHeight = dropdown.getBoundingClientRect().height
          dropdown.classList.remove('js-full-height')
          setTimeout(() => {
            dropdown.setAttribute('style', `height: ${dropdownHeight}px;`)
          }, 1)
          // The Dropdown is now open

          // Handle the filters
          const filters = [...document.querySelectorAll('.js-news-filter')]
          filters.forEach((filter) => {
            filter.addEventListener('click', (e) => {
              e.preventDefault()
              e.stopPropagation()
              ddTrigger.textContent = filter.textContent
              closeDropdown(dropdownHeightWhenClosed, ddTrigger)
            })
          })

          // End of filter functionality
        } else {
          closeDropdown(dropdownHeightWhenClosed)
        }
      })
    }
  }
}
