import polyfillClassList from "./classlistPolyfill";
import "simplebar";
import {
  heroScroll,
  scrollUp,
  showMobileFilters,
  openModal,
  showDesktopNav
} from "./navigation";
import { carousel } from "./sliders";
import { handleFormLabels } from "./forms";
import initTable from "./table";
import initMap from "./maps";
import { filterNews, handleNewsDropdown } from "./news";
import "svgxuse";
import initCookie from "./cookieConsent";
import $ from "jquery";
// Pages

polyfillClassList();

const home = document.body.classList.contains("home");
const apartments = document.body.classList.contains(
  "post-type-archive-apartment"
);
const apartment = document.body.classList.contains("single-apartment");
const environment = document.body.classList.contains(
  "page-template-template-environment"
);
const news = document.body.classList.contains("page-template-archive-php");

// Globals

scrollUp();
openModal();
initCookie();
showDesktopNav();

// Home

if (home) {
  heroScroll();
  carousel();
}

// Apartments

if (apartments) {
  initTable();
  showMobileFilters();
}

if (apartment) {
  openModal();
}

if (environment) {
  window.addEventListener("load", e => {
    initMap();
  });
}

if (news) {
  filterNews();
  handleNewsDropdown();
  // stickyNewsFilter()
}

jQuery(document).on("gform_post_render", function() {
  if ($(".gform_footer").length > 0) {
    $(".gform_footer").each(function() {
      $(this)
        .children(".gform_button")
        .wrap("<span class='c-submit-wrap'></span>");
    });
  }
  if ($(".lostpassword-submit").length > 0) {
    $(".lostpassword-submit").each(function() {
      $(this)
        .children(".big-btn")
        .wrap("<span class='c-submit-wrap'></span>");
    });
  }
});

const labelOver = function() {
  return this.each(function() {
    var label = jQuery(this);
    var f = label.attr("for");
    if (f) {
      var input = jQuery("#" + f);

      this.hide = function() {
        label.closest("li").addClass("is-focused");
      };
      this.show = function() {
        if (input.val() === "") label.closest("li").removeClass("is-focused");
      };

      input.focus(this.hide);
      input.blur(this.show);
      if (input.val() !== "") this.hide();
    }
  });
};

$.fn.labelOver = function(overClass) {
  return this.each(function() {
    const label = $(this);
    const f = label.attr("for");
    if (f) {
      const input = $(`#${f}`);

      this.hide = function() {
        label.closest("li").addClass("is-focused");
      };
      this.show = function() {
        if (input.val() === "") label.closest("li").removeClass("is-focused");
      };

      input.focus(this.hide);
      input.blur(this.show);
      if (input.val() !== "") this.hide();
    }
  });
};

if ($(".gfield label").length > 0) {
  $(".gfield label").labelOver();
}

jQuery(document).on("gform_post_render", function() {
  if ($(".gfield label").length > 0) {
    $(".gfield label").labelOver();
  }
});

export { handleFormLabels, openModal };
