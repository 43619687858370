import styles from './mapsConfig'

// Custom popup

function createPopupClass() {
  /**
   * A customized popup on the map.
   * @param {!google.maps.LatLng} position
   * @param {!Element} content The bubble div.
   * @constructor
   * @extends {google.maps.OverlayView}
   */
  function Popup(position, content) {
    this.position = position

    content.classList.add('map-popup__bubble')

    // This zero-height div is positioned at the bottom of the bubble.
    const bubbleAnchor = document.createElement('div')
    bubbleAnchor.classList.add('map-popup__bubble-anchor')
    bubbleAnchor.appendChild(content)

    // This zero-height div is positioned at the bottom of the tip.
    this.containerDiv = document.createElement('div')
    this.containerDiv.classList.add('map-popup__container')
    this.containerDiv.appendChild(bubbleAnchor)

    // Optionally stop clicks, etc., from bubbling up to the map.
    google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv)
  }
  // ES5 magic to extend google.maps.OverlayView.
  Popup.prototype = Object.create(google.maps.OverlayView.prototype)

  /** Called when the popup is added to the map. */
  Popup.prototype.onAdd = function () {
    this.getPanes().floatPane.appendChild(this.containerDiv)
  }

  /** Called when the popup is removed from the map. */
  Popup.prototype.onRemove = function () {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv)
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  Popup.prototype.draw = function () {
    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)

    // Hide the popup when it is far out of view.
    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none'
    if (display === 'block') {
      this.containerDiv.style.left = `${divPosition.x}px`
      this.containerDiv.style.top = `${divPosition.y}px`
    }
    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display
    }
  }

  return Popup
}
// To keep track of some "global" variables, I create an object

function EnvironmentMap() {
  this.map = null
  this.markers = []

  this.init = () => {
    // The init functions sets up the map and the markers
    const building = document.querySelector('.js-building')
    const mapCenter = new google.maps.LatLng(building.dataset.lat, building.dataset.lng)
    const mapElement = document.getElementById('map')
    const map = new google.maps.Map(mapElement, {
      center: mapCenter,
      zoom: 14,
      disableDefaultUI: true,
      styles,
    })
    this.map = map

    // Set the main pin
    if (building) {
      const position = new google.maps.LatLng(building.dataset.lat, building.dataset.lng)
      const Popup = createPopupClass()
      const popup = new Popup(
        new google.maps.LatLng(building.dataset.lat, building.dataset.lng),
        building,
      )
      popup.setMap(this.map)
    }
    // The points are all the markers that will be displayed on the map
    const points = [...document.querySelectorAll('.js-map-point[data-category]')]
    this.activatePointsOfInterest(points)
    this.activateMapTabs()
  }
  this.activateMapTabs = () => {
    /**
     * Add event listeners to the tabs that control the points.
     * @param {!NodeList} points
     */
    // Get the tabs
    const tabs = [...document.querySelectorAll('.js-map-tab')]

    tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {
        const cat = tab.dataset.category
        // On click, we check if there are any markers
        if (this.markers) {
          // we create a new bounds object
          const bounds = new google.maps.LatLngBounds()

          Object.keys(this.markers).forEach((key) => {
            // We loop through all the properties of the markers object until we find one
            // that matches the category of the tab that was clicked
            if (key == cat) {
              // Once we find it, we go through each point and show it, as well as increase the bounds
              this.markers[key].forEach((point) => {
                point.containerDiv.classList.remove('u-is-hidden')
                bounds.extend(point.position)
              })
            } else {
              // We hide all other points
              this.markers[key].forEach((point) => {
                point.containerDiv.classList.add('u-is-hidden')
              })
            }
          })
          // Once we have all the points visible, we set the new bounds
          this.map.fitBounds(bounds)

        } // End of if this.markers
        // Add active style to this and remove it from other
        tabs.forEach((_tab) => {
          if (_tab == tab) {
            _tab.classList.add('active')
          } else {
            _tab.classList.remove('active')
          }
        })
      })
    })
  }

  this.activatePointsOfInterest = (points) => {
    /**
     * Add customized popups to the map.
     * @param {!NodeList} points
     */
    // Get the map and create a bounds object
    const { map } = this
    const bounds = new google.maps.LatLngBounds()

    points.forEach((point) => {
      // Get the position and create a new Popup
      const position = new google.maps.LatLng(point.dataset.lat, point.dataset.lng)
      const Popup = createPopupClass()
      const popup = new Popup(new google.maps.LatLng(point.dataset.lat, point.dataset.lng), point)
      popup.setMap(map)
      // Add a hidden class to the Container Div
      popup.containerDiv.classList.add('u-is-hidden')
      // Get the category of the point
      const { category } = point.dataset
      // This.markers is an object that keeps track of our points
      // we check if we have seen this category before
      if (this.markers.hasOwnProperty(category)) {
        // If yes, we push on to that array
        this.markers[category].push(popup)
      } else {
        // Otherwise, we init the array and then push to it
        this.markers[category] = []
        this.markers[category].push(popup)
      }
      // Finally, we extend the bounds by this points position
      bounds.extend(position)
    })
    // after looping through all the point, we set the fitBounds and a zoom
    this.map.fitBounds(bounds)
  }
}

function initMap() {
  const map = new EnvironmentMap()
  map.init()
}

export default initMap
