import { initFloors, openFloorsModal } from "./floors";

const Tabulator = require("tabulator-tables");

function activateFiltersFloorPlans() {
  const target = document.querySelector(".filters__floor-plans");
  if (target) {
    const floorNumbers = [...target.querySelectorAll(".js-floor-number")];
    if (floorNumbers) {
      floorNumbers.forEach(floorNumber => {
        floorNumber.addEventListener("click", e => {
          e.preventDefault();
          const floorNo = floorNumber.dataset.floornr;
        });
      });
    }
  }
}

function columnSwitch(colAttribute) {
  // Sets up a column as per its type
  let sorter = null;
  let formatter = null;
  let widthGrow = null;
  let responsive = 0;
  let formatterFunction = null;
  let width;
  let formatterParams = null;
  let align = null;
  switch (colAttribute) {
    case "available":
      sorter = "string";
      formatter = "plaintext";
      responsive = 0;
      break;
    case "nr":
      align = "left";
      sorter = "string";
      formatter = "plaintext";
      responsive = 0;
      break;
    case "floor":
      sorter = "number";
      formatter = "plaintext";
      responsive = 0;

      break;
    case "rooms":
      sorter = "number";
      formatter = "plaintext";
      responsive = 0;

      break;
    case "balcony":
      sorter = "number";
      formatter = "tickCross";
      responsive = 10;

      break;
    case "fireplace":
      sorter = "number";
      formatter = "tickCross";
      responsive = 11;

      break;
    case "sauna":
      sorter = "number";
      formatter = "tickCross";
      responsive = 12;

      break;
    case "url":
      break;
    case "view":
      align = "left";
      sorter = "string";
      formatter = "plaintext";
      widthGrow = 1;
      responsive = 13;

      break;
    case "area":
      sorter = "number";
      formatter = "plaintext";
      responsive = 0;

      formatterFunction = function(cell, formatterParams, onRendered) {
        return `${cell.getValue()}m<sup>2</sup>`;
      };
      break;
    case "price":
      sorter = "number";
      formatter = "money";
      responsive = 0;

      align = "left";
      formatterParams = {
        precision: 0,
        thousand: " ",
        symbol: "€",
        symbolAfter: true
      };
      break;
    default:
      sorter = "string";
      formatter = "plaintext";
      responsive = 0;
      break;
  }
  return {
    sorter,
    formatter,
    widthGrow,
    responsive,
    width,
    formatterFunction,
    formatterParams,
    align
  };
}

function createColumn(htmlThElement) {
  const columnProperties = columnSwitch(htmlThElement.dataset.column);

  const column = {
    title: htmlThElement.textContent,
    field: htmlThElement.dataset.column,
    sorter: columnProperties.sorter,
    formatter: columnProperties.formatter,
    widthGrow: columnProperties.widthGrow ? columnProperties.widthGrow : 1,
    responsive: columnProperties.responsive,
    resizable: false,
    align: columnProperties.align ? columnProperties.align : "center"
  };

  if (columnProperties.width) {
    column.width = columnProperties.width;
  }
  if (columnProperties.formatterFunction) {
    column.formatter = columnProperties.formatterFunction;
  }
  if (columnProperties.formatterParams) {
    column.formatterParams = columnProperties.formatterParams;
  }
  return column;
}

// composeFilter

// This function exists because I am unable to get table.getFilters() to work
// when the filter is an array. Filter of type OR need to be grouped in an array
// .getFilters() just return undefined for an array. Since I can't count on it,
// I made a function where I find all the active filters and set them all at once
// This way I don't have to find an existing filter and add to it.

function initTable() {
  // Get the th elements from the table
  const tableColumnsTemp = [
    ...document.querySelectorAll(".js-table > thead > tr > th")
  ];
  // Create Tabulator columns out of them
  const parsedColumns = [];
  tableColumnsTemp.forEach(col => {
    const column = createColumn(col);
    parsedColumns.push(column);
  });
  // Get building svg and floors

  const buildingSvg = document.querySelector("#Floors");
  const floors = [...buildingSvg.querySelectorAll(".js-floor")];
  const apts = [...document.querySelectorAll(".js-floor-apt")];
  const floorNos = [...document.querySelectorAll(".js-floor-number")];

  // Init table
  const table = new Tabulator(document.querySelector(".js-table"), {
    columns: parsedColumns,
    responsiveLayout: "hide",
    layout: "fitDataFill",
    height: "100%",
    layoutColumnsOnNewData: true,
    initialSort: [{ column: "nr", dir: "asc" }],
    rowFormatter(row) {
      const data = row.getData();
      if (data.available == "sold") {
        row.getElement().classList.add("inactive-row");
      }
    },
    rowClick(e, row) {
      window.location = row._row.data.url;
    },
    rowMouseEnter(e, row) {
      const { floor } = row._row.data;
      const { nr } = row._row.data;

      const allFloors = [...document.querySelectorAll(".js-floor")];
      if (allFloors) {
        allFloors.forEach(floor => {
          floor.classList.remove("active-floor");
        });
      }

      if (floors[parseInt(floor - 1)]) {
        floors[parseInt(floor - 1)].classList.add("active-floor");
      }

      document.querySelector(".js-floor-number").classList.remove("active");

      const allFloorPlans = [...document.querySelectorAll(".js-floor-plan")];
      if (allFloorPlans) {
        allFloorPlans.forEach(floorPlan => {
          floorPlan.classList.add("u-is-hidden");
        });
      }

      const allFloorNumbers = [
        ...document.querySelectorAll(".js-floor-number")
      ];
      if (allFloorNumbers) {
        allFloorNumbers.forEach(floorNumber => {
          floorNumber.classList.remove("active");
        });
      }

      document
        .querySelector(`.js-floor-number[data-floornr="${floor}"]`)
        .classList.add("active");

      document
        .querySelector(`.js-floor-plan[data-floornr="${floor}"]`)
        .classList.remove("u-is-hidden");

      if (document.getElementById(nr)) {
        console.log(nr);
        document.getElementById(nr).classList.add("active-apt");
      }
    },
    rowMouseLeave(e, row) {
      const { floor } = row._row.data;
      const { nr } = row._row.data;

      if (floors[parseInt(floor - 1)]) {
        floors[parseInt(floor - 1)].classList.remove("active-floor");
      }

      if (document.getElementById(nr)) {
        document.getElementById(nr).classList.remove("active-apt");
      }
    }
  });
  // Manually hide the last column denoting the apartments availability
  table.hideColumn("available");
  table.hideColumn("url");
  table.hideColumn("fireplace");
  table.redraw();

  // Set up building hover
  floors.forEach(_floor => {
    _floor.addEventListener("mouseenter", e => {
      const floorNo = _floor.getAttribute("data-floor");

      e.preventDefault();

      _floor.classList.add("active-floor");

      document.querySelector(".js-floor-number").classList.remove("active");

      const allFloorPlans = [...document.querySelectorAll(".js-floor-plan")];
      if (allFloorPlans) {
        allFloorPlans.forEach(floorPlan => {
          floorPlan.classList.add("u-is-hidden");
        });
      }

      const allFloorNumbers = [
        ...document.querySelectorAll(".js-floor-number")
      ];
      if (allFloorNumbers) {
        allFloorNumbers.forEach(floorNumber => {
          floorNumber.classList.remove("active");
        });
      }

      document
        .querySelector(`.js-floor-number[data-floornr="${floorNo}"]`)
        .classList.add("active");

      document
        .querySelector(`.js-floor-plan[data-floornr="${floorNo}"]`)
        .classList.remove("u-is-hidden");
    });

    _floor.addEventListener("mouseleave", e => {
      const floorNo = _floor.getAttribute("data-floor");
      e.preventDefault();
      _floor.classList.remove("active-floor");
    });
    _floor.addEventListener("click", e => {
      const floorNo = _floor.getAttribute("data-floor");
      document
        .querySelector(`.js-floor-number[data-floornr="${floorNo}"]`)
        .classList.add("active");
    });
  });

  // Set up building hover
  floors.forEach(_floor => {
    _floor.addEventListener("mouseenter", e => {
      const floorNo = _floor.getAttribute("data-floor");

      e.preventDefault();

      const allFloors = [...document.querySelectorAll(".js-floor")];
      if (allFloors) {
        allFloors.forEach(floor => {
          floor.classList.remove("active-floor");
        });
      }

      _floor.classList.add("active-floor");

      document.querySelector(".js-floor-number").classList.remove("active");

      const allFloorPlans = [...document.querySelectorAll(".js-floor-plan")];
      if (allFloorPlans) {
        allFloorPlans.forEach(floorPlan => {
          floorPlan.classList.add("u-is-hidden");
        });
      }

      const allFloorNumbers = [
        ...document.querySelectorAll(".js-floor-number")
      ];
      if (allFloorNumbers) {
        allFloorNumbers.forEach(floorNumber => {
          floorNumber.classList.remove("active");
        });
      }

      document
        .querySelector(`.js-floor-number[data-floornr="${floorNo}"]`)
        .classList.add("active");

      document
        .querySelector(`.js-floor-plan[data-floornr="${floorNo}"]`)
        .classList.remove("u-is-hidden");
    });

    _floor.addEventListener("mouseleave", e => {
      const floorNo = _floor.getAttribute("data-floor");
      e.preventDefault();
      _floor.classList.remove("active-floor");
    });
    _floor.addEventListener("click", e => {
      const floorNo = _floor.getAttribute("data-floor");
      document
        .querySelector(`.js-floor-number[data-floornr="${floorNo}"]`)
        .classList.add("active");
    });
  });

  // Set up floor numbers hover
  floorNos.forEach(number => {
    number.addEventListener("mouseenter", e => {
      const floorNo = number.getAttribute("data-floornr");

      e.preventDefault();

      const allFloorNumbers = [
        ...document.querySelectorAll(".js-floor-number")
      ];
      if (allFloorNumbers) {
        allFloorNumbers.forEach(floorNumber => {
          floorNumber.classList.remove("active");
        });
      }

      number.classList.add("active");

      document.querySelector(".js-floor").classList.remove("active-floor");

      const allFloors = [...document.querySelectorAll(".js-floor")];
      if (allFloors) {
        allFloors.forEach(floor => {
          floor.classList.remove("active-floor");
        });
      }

      const allFloorPlans = [...document.querySelectorAll(".js-floor-plan")];
      if (allFloorPlans) {
        allFloorPlans.forEach(floorPlan => {
          floorPlan.classList.add("u-is-hidden");
        });
      }

      document
        .querySelector(`.js-floor-plan[data-floornr="${floorNo}"]`)
        .classList.remove("u-is-hidden");

      document
        .querySelector(`.js-floor[data-floor="${floorNo}"]`)
        .classList.add("active-floor");
    });

    number.addEventListener("mouseleave", e => {
      const floorNo = number.getAttribute("data-floornr");
      e.preventDefault();

      const allFloors = [...document.querySelectorAll(".js-floor-number")];
      if (allFloors) {
        allFloors.forEach(floor => {
          floor.classList.remove("active-floor");
        });
      }
    });

    number.addEventListener("click", e => {
      const floorNo = number.getAttribute("data-floornr");
      document
        .querySelector(`.js-floor-plan[data-floornr="${floorNo}"]`)
        .classList.add("active-floor");
    });
  });

  // Set up filters
  const filters = [...document.querySelectorAll(".js-filter")];
  filters.forEach(filter => {
    filter.addEventListener("click", e => {
      if (filter.classList.contains("active")) {
        filter.classList.remove("active");
      } else {
        filter.classList.add("active");
      }
      // Add an exception for Availability filters which should behave like radio buttons
      if (filter.dataset.column == "available") {
        filters.forEach(_filter => {
          // Remove active class from all other filters that
          // are of the column 'available'
          // and
          // are not the filter that was clicked
          if (_filter.dataset.column == "available" && _filter !== filter) {
            _filter.classList.remove("active");
          }
        });
      }
      composeFilter(table);
    });
  });

  // Set up clear filters button
  const clearAll = document.querySelector(".js-clear-filters");
  if (clearAll) {
    clearAll.addEventListener("click", e => {
      // Disable all filter
      filters.forEach(filterBtn => {
        filterBtn.classList.remove("active");
      });
      e.preventDefault();
      table.clearFilter();
      clearAll.classList.add("u-is-hidden");
    });
  }

  const apartments = [...document.querySelectorAll(".js-floor-apt")];

  apartments.forEach(apartment => {
    // Find the html element that contains data about this apartment
    const nr = apartment.getAttribute("id");
    const info = document.querySelector(`.js-apt-info[data-no="${nr}"]`);

    // Check if we have the info element for this apartment
    // If yes, we activate the event listeners
    if (info) {
      // If apartment is sold, we add a full opacity class called hidden
      if (info.getAttribute("data-sold") == 1) {
        apartment.classList.add("hidden");
      }
      apartment.addEventListener("click", e => {
        if (info.getAttribute("data-sold") != 1) {
          window.location = info.dataset.url;
        }
      });
      apartment.addEventListener("mouseenter", event => {
        event.stopPropagation();
        event.preventDefault();
        if (info.getAttribute("data-sold") != 1) {
          apartment.classList.add("active-apt");
        }
      });
      apartment.addEventListener("mouseleave", e => {
        e.stopPropagation();
        apartment.classList.remove("active-apt");
      });
    }
  });

  activateFiltersFloorPlans();
}

function composeFilter(table) {
  // Create an array to house the set of filters
  const tableFilter = [];
  // Get all filter items
  const filterItems = [...document.querySelectorAll(".js-filter")];
  // Create a parameters options to compose a custom filter with
  const params = {};
  if (filterItems) {
    // For each filter item
    filterItems.forEach(filter => {
      // We check if it is active
      if (filter.classList.contains("active")) {
        // If it is active,
        // We check if we already have that parameter in our params object
        if (params.hasOwnProperty(filter.dataset.column)) {
          // if yes, we add on to that array
          params[filter.dataset.column].push({
            field: filter.dataset.column,
            type: filter.dataset.type,
            value: filter.dataset.value
          });
        } else {
          // if not, we create an array with the first filter
          params[filter.dataset.column] = [
            {
              field: filter.dataset.column,
              type: filter.dataset.type,
              value: filter.dataset.value
            }
          ];
        }
      }
    });
    // Once we have all the params, we compose the filter
    // We will also need to handle some exceptions, such as the area filter

    Object.keys(params).forEach(key => {
      // The area field is an exception because we have "range" filter,
      // e.g 50 - 100
      // The current solution is to intercept those values and insert one
      // filter for
      // > 50
      // and one for
      // < 100
      if (key == "area") {
        params[key].forEach(areaFilter => {
          const areaValues = areaFilter.value
            .split("-")
            .map(meters => parseInt(meters));
          if (areaValues.length > 1) {
            const largerThan = {
              field: areaFilter.field,
              type: ">",
              value: areaValues[0]
            };
            const smallerThan = {
              field: areaFilter.field,
              type: "<",
              value: areaValues[1]
            };
            tableFilter.push(largerThan);
            tableFilter.push(smallerThan);
          } else {
            tableFilter.push({
              field: areaFilter.field,
              type: areaFilter.type,
              value: areaValues[0]
            });
          }
        });
      } else if (params[key].length > 1) {
        // We push the array of params if there are multiple in the group
        tableFilter.push(params[key]);
      } else {
        // We pust the first object if there is only 1 param
        tableFilter.push(params[key][0]);
      }
    });
  }

  // Show clear filter button if need be
  const clearAll = document.querySelector(".js-clear-filters");
  if (tableFilter.length === 0) {
    if (clearAll) {
      clearAll.classList.add("u-is-hidden");
    }
  } else if (clearAll) {
    clearAll.classList.remove("u-is-hidden");
  }
  table.setFilter(tableFilter);
}

export default initTable;
