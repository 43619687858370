import { fadeInElement, fadeOutElement } from './navigation'

function setCookie(name, value, days) {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`
}

function initCookie() {
  window.addEventListener('load', (e) => {
    // Check if cookie exists
    const name = 'cookieConsent'
    const cookie = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
      ? document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)[2] == 'on'
      : null
    // If cookie doesnt exist
    if (!cookie) {
      const cookieElement = document.querySelector('.js-cookie-consent')
      if (cookieElement) {
        fadeInElement(cookieElement, 'u-is-hidden', 'u-transparent')
        const cookieClose = cookieElement.querySelector('.js-close-cookie')
        if (cookieClose) {
          cookieClose.addEventListener('click', () => {
            setCookie('cookieConsent', 'on', 14)
            fadeOutElement(cookieElement, 'u-is-hidden', 'u-transparent')
          })
        }
      }
    }
  })
}

export default initCookie
