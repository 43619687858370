import {
  initGalleryModal,
  initViewGallery,
  initArticleGalleryModal
} from "./sliders";

export function fadeInElement(
  /**
   * A function for fading in an element from display: none with a transition class.
   */
  element,
  hideClass = "u-is-hidden",
  transitionClass = "u-translateY-negative"
) {
  element.classList.remove(hideClass);
  setTimeout(() => {
    element.classList.remove(transitionClass);
  }, 1);
}

export function fadeOutElement(
  /**
   * A function for fading out an element from display: none with a transition class.
   *
   */
  element,
  hideClass = "u-is-hidden",
  transitionClass = "u-translateY-negative",
  timeout = 600
) {
  element.classList.add(transitionClass);
  setTimeout(() => {
    element.classList.add(hideClass);
  }, timeout);
}

export function heroScroll() {
  const scrollBtn = document.querySelector(".js-hero-scroll");
  // Target the second section, or in this case div;

  const hero = document.querySelector("div.hero");
  const heroHeight = hero.getBoundingClientRect().height;

  const offset = 150;
  if (hero) {
    scrollBtn.addEventListener("click", e => {
      const currentScroll = window.pageYOffset;
      e.preventDefault();
      window.scrollTo({
        top: heroHeight + offset,
        behavior: "smooth"
      });
    });
  }
}

export function scrollUp() {
  const scrollBtn = document.querySelector(".js-scroll-up");
  if (scrollBtn) {
    scrollBtn.addEventListener("click", e => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }
}

function toggleBackdrop(on) {
  const backdrop = document.querySelector(".js-backdrop");
  if (backdrop) {
    if (on === true) {
      backdrop.classList.remove("u-is-hidden");
      setTimeout(() => {
        backdrop.classList.add("u-opacity");
      }, 1);
    } else {
      backdrop.classList.remove("u-opacity");
      setTimeout(() => {
        backdrop.classList.add("u-is-hidden");
      }, 600);
    }
  }
}

function toggleBodyScroll(on) {
  if (on === true) {
    document.body.classList.add("u-modal-open");
  } else {
    document.body.classList.remove("u-modal-open");
  }
}

export function showDesktopNav() {
  const menuBtn = document.querySelector(".nav__menu-icon");
  const fullNav = document.querySelector(".nav--expanded");
  const menuClose = fullNav.querySelector(".nav__close-icon");
  const backdrop = document.querySelector(".js-backdrop");
  if (menuBtn && fullNav && menuClose) {
    menuBtn.addEventListener("click", e => {
      e.preventDefault();
      fadeInElement(fullNav);
      toggleBodyScroll(true);
      toggleBackdrop(true);
      fullNav.dataset.isopen = "true";
      // Enable close on click on backdrop
      if (backdrop) {
        backdrop.addEventListener("click", e => {
          closeExpandedNav();
        });
      }
    });
    menuClose.addEventListener("click", e => {
      fadeOutElement(fullNav);
      toggleBodyScroll(false);
      toggleBackdrop(false);
    });
  }
}

export function showMobileFilters() {
  const filtersBtn = document.querySelector(".js-open-mobile-filter");
  const mobileFilters = document.querySelector(".filters--mobile");
  const width = window.innerWidth;
  if (filtersBtn) {
    filtersBtn.addEventListener("click", e => {
      e.preventDefault();
      if (width < 1100 && mobileFilters) {
        fadeInElement(mobileFilters);
        const close = mobileFilters.querySelector(".filters__close-icon");
        close.addEventListener("click", e => {
          fadeOutElement(mobileFilters);
        });
      }
    });
  }
}

export function openModal(specificTarget) {
  // Get the buttons
  const btns = [...document.querySelectorAll(".js-open-modal")];
  // Get the modals for later
  const modals = [...document.querySelectorAll(".js-modal")];

  if (btns) {
    btns.forEach(btn => {
      if (btn.tagName == "LI") {
        btn = btn.firstChild;
      }
      btn.addEventListener("click", e => {
        const target = btn.getAttribute("href");
        const modal = document.querySelector(`${target}`);
        let modalClose = null;
        if (modal) {
          modalClose = modal.querySelector(".js-modal-close");
        }
        if (modal && modalClose) {
          e.preventDefault();
          // Close all other modals
          modals.forEach(_modal => {
            const otherModal = _modal;
            if (otherModal.dataset.open === "true") {
              otherModal.dataset.open = "false";
              otherModal.classList.add("u-is-hidden");
              otherModal.classList.add("u-transparent");
            }
          });
          // Open the modal
          modal.dataset.open = "true";
          document.body.classList.add("u-no-overflow");
          fadeInElement(modal, "u-is-hidden", "u-transparent");
          // Exceptions and special cases
          if (target === "#view-modal") {
            initViewGallery();
          }
          if (target.slice(0, 22) === "#article-gallery-modal") {
            initArticleGalleryModal(target);
          }

          if (target === "#gallery-modal") {
            initGalleryModal(target);
          }
          //
          modalClose.addEventListener("click", e => {
            modal.dataset.open = "false";

            document.body.classList.remove("u-no-overflow");
            fadeOutElement(modal, "u-is-hidden", "u-transparent");
          });
        }
      });
    });
  } // End Main If Statement
  // In some cases the modal needs to be opened more or less immediately
  if (specificTarget) {
    const specificModalAnchor = document.querySelector(
      `a[href*="${specificTarget}"]`
    );
    if (specificModalAnchor) {
      specificModalAnchor.click();
    }
  }
}
