import Swiper from "swiper/dist/js/swiper";

const Masonry = require("masonry-layout");

export function carousel() {
  const prev = document.querySelector(".js-carousel-prev");
  const next = document.querySelector(".js-carousel-next");

  const carouselSwiper = document.querySelector(".js-carousel");
  if (carouselSwiper) {
    const mySwiper = new Swiper(carouselSwiper, {
      speed: 400,
      spaceBetween: 0,
      slidesPerView: 1,
      autoplay: {
        delay: 3200
      },
      navigation: {
        nextEl: next,
        prevEl: prev
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      on: {
        slideChange() {
          const currentDisplay = document.querySelector(".js-current-slide");
          if (currentDisplay) {
            currentDisplay.textContent =
              this.activeIndex + 1 < 10
                ? `0${this.activeIndex + 1}`
                : this.activeIndex + 1;
          }
        }
      }
    });
  }
}

export function initViewGallery() {
  const viewModal = document.querySelector("#view-modal");
  if (viewModal) {
    const gallery = viewModal.querySelector(".js-carousel");
    const pageWidth = window.innerWidth;
    const prev = viewModal.querySelector(".js-carousel-prev");
    const next = viewModal.querySelector(".js-carousel-next");

    if (gallery) {
      const swiper = new Swiper(gallery, {
        slidesPerView: 1,
        init: true,
        navigation: {
          nextEl: next,
          prevEl: prev
        }
      });
    }
  }
}

export function initGalleryModal(modalID) {
  const modal = document.querySelector(modalID);
  const grids = [...modal.querySelectorAll(".js-grid")];
  const gridTitles = [...modal.querySelectorAll(".js-gallery-title")];
  const gridBreakpoint = 0;

  if (window.innerWidth < gridBreakpoint) {
    const main = modal.querySelector(".modal-gallery__responsive-main");
    const swipers = [...main.querySelectorAll(".js-carousel")];
    const titles = [...modal.querySelectorAll(".js-gallery-title")];
    if (swipers) {
      if (titles) {
        titles.forEach(title => {
          const targetSwiper = main.querySelector(
            `div[data-index="${title.dataset.index}"]`
          );
          title.addEventListener("click", e => {
            targetSwiper.classList.remove("u-is-hidden");
            title.classList.add("active-title");
            titles.forEach(otherTitle => {
              if (otherTitle !== title) {
                otherTitle.classList.remove("active-title");
              }
            });
            swipers.forEach(otherSwiper => {
              if (otherSwiper !== targetSwiper) {
                otherSwiper.classList.add("u-is-hidden");
              }
            });
            // Init the swiper
            if (targetSwiper.getAttribute("data-init") !== "true") {
              const responsiveGallery = new Swiper(targetSwiper, {
                slidesPerView: 1,
                init: true
              });
              targetSwiper.setAttribute("data-init", "true");
            }
          });
        });
        titles[0].click();
      }
    }
  } else if (gridTitles && grids) {
    // Load the first gallery by default

    // Add event listener to each click
    gridTitles.forEach(title => {
      title.addEventListener("click", e => {
        // On click, loop through grids
        const titleIndex = title.dataset.index;
        grids.forEach(grid => {
          // If grid index and title index line up
          if (grid.dataset.index == titleIndex) {
            // Show it
            grid.classList.remove("u-is-hidden");
            if (grid.dataset.init !== "true") {
              // If it has not been initialised, initialize it
              grid.dataset.init = "true";
              const msnry = new Masonry(grid, {
                itemSelector: ".grid-item",
                columnWidth: ".grid-sizer",
                gutter: ".gutter-sizer",
                percentPosition: true
              });
            }
          } else {
            grid.classList.add("u-is-hidden");
          }
        });
        // Loop through titles and add an active class
        gridTitles.forEach(gridTitle => {
          if (gridTitle == title) {
            gridTitle.classList.add("active-title");
          } else {
            gridTitle.classList.remove("active-title");
          }
        });
      });
    });
    // Initialize first be default
    gridTitles[0].click();
  }
}

export function initArticleGalleryModal(modalID) {
  const modal = document.querySelector(modalID);
  const grid = modal.querySelector(".js-grid");

  const gridBreakpoint = 0;
  if (window.innerWidth < gridBreakpoint) {
    const main = modal.querySelector(".modal-gallery__responsive-main");
    const swiper = main.querySelector(".js-carousel");

    if (swiper) {
      const responsiveGallery = new Swiper(swiper, {
        slidesPerView: 1,
        init: true,
        autoplay: {
          delay: 3200
        }
      });
    }
  } else if (grid) {
    const msnry = new Masonry(grid, {
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      gutter: ".gutter-sizer"
    });
  }
}
